<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!-- 确认所在园区  -->
              <div class="range" id="glhtrk">
                <h4 class="title">管理后台入口</h4>
                <el-divider></el-divider>
                <p class="enterance">
                  <ul>
                    <li>1.智慧湾：https://admin.kefan.com/</li>
                    <li>2.智慧坊：https://zhf.wis-p.cn/</li>
                    <li>3.交运智慧湾：https://jyzhw.jy-wisdombay.cn/</li>
                    <li>4.智慧七立方：https://wis777.kefan.com/</li>
                    <li>5.科房旗下园区：https://kf.kefan.com/</li>
                  </ul>
                </p>
              </div>
              <!-- 账单管理 -->
              <div class="range" id="zdgl">
                <h4 class="title">账单管理</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击小程序左侧账单管理进入账单管理页面（如图2.1）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.aurl[0]"
                :preview-src-list="img.aurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.1</p>
                <p class="describe">
                  2.点击红色方框“租金物业费账单确认”（如图2.2）进入租金物业费确认页面（如图2.3），此处所有账单未结算状态（可编辑金额等数据）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.burl[0]"
                :preview-src-list="img.burl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.2</p>
                <el-image 
                style="width:100%;"
                :src="img.curl[0]"
                :preview-src-list="img.curl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.3</p>
                <p class="describe">
                  3.点击蓝色方框“水电费账单确认”（如图2.2.1）进入水电费确认页面（如图2.3.1）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.durl[0]"
                :preview-src-list="img.durl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.2.1</p>
                <el-image 
                style="width:100%;"
                :src="img.eurl[0]"
                :preview-src-list="img.eurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.3.1</p>
                <p class="describe">
                    点击紫色方框“收款及发票登记跟踪”（如图2.2.2）进入收款、发票登记页面（如图2.3.2）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.furl[0]"
                :preview-src-list="img.furl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.2.2</p>
                <p class="describe">
                    此处所有账单状态包含”已支付”、”部分支付”、”未结算状态”状态，可编辑账单金额，添加备注。但若需改账单详情中水电费明细，需修改表格重新上传
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.gurl[0]"
                :preview-src-list="img.gurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.3.2</p>
                 <p class="describe">
                    点击白色方框“历史已结算账单（如图2.2.3）进入已结算账单界面（如图2.3.3）
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.hurl[0]"
                :preview-src-list="img.hurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.2.3</p>
                 <p class="describe">
                    图2.3.3所有账单状态为”已结算”状态，不可编辑账单金额且不可回退，但可添加备注。账单在完成付款登记和发票登记后会变为”已结算”状态进入此处，若金额账期有误需上报至数字技术部，点击账单详情告知账单id（图2.3.4）
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.iurl[0]"
                :preview-src-list="img.iurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.3.3</p>
                <el-image 
                style="width:100%;"
                :src="img.jurl[0]"
                :preview-src-list="img.jurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.3.4</p>
                 <p class="describe">
                    4.此处为有特殊条款账单触发，如提成类合同或需定时补签补充条款的合同。此处可点击合同账单跳转至合同内所有账单界面来增删账单，账单修改完毕后可点击关闭来关闭通知
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.kurl[0]"
                :preview-src-list="img.kurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.3.5</p>
              </div>

              <!-- 账单筛选和排序 -->
              <div class="range" id="zdsxhpx">
                <h4 class="title">账单筛选和排序</h4>
                <el-divider></el-divider>
                 <p class="describe">
                    1.账单确认上方可输入客户名称并对金额、类型和周期进行筛选并可按照租赁物业和账单周期进行排序（如图2.4）；点击合同中客户名称可以跳转至客户合同内所有账单，但此处只能编辑合同金额、备注和收款银行。
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.lurl[0]"
                :preview-src-list="img.lurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.4</p>
                <p class="info">
                    注：其中费用类型可多选筛选账单类型；账单周期处可点击两个日期取其区间（此期间若点到框外会取消选择），也可手动输入两个日期为筛选区间，格式为xxxx-xx-xx
                </p>
              </div>
              <!-- 账单选择和确认 -->
              <div class="range" id="zdxzhqr">
                <h4 class="title">账单选择和确认</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1. 点击所示红色方框勾选需要确认的账单（如图2.5）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.murl[0]"
                :preview-src-list="img.murl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.5</p>
                <p class="describe">
                  也可以点击客户名称左侧小方框全选当前页面所有账单（如图2.6）
                </p>
                <el-image 
                style="width:100%"
                :src="img.nurl[0]"
                :preview-src-list="img.nurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.6</p>
                <p class="describe">
                  3. 点击账单确认按钮（如图2.7）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.ourl[0]"
                :preview-src-list="img.ourl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.7</p>
                <!-- 4 -->
                <p class="describe">
                  4.跳转出账单确认框（如图2.8）勾选确认发送并通知并点击确定按钮
                </p>
                <el-image 
                style="width:100%;"
                :src="img.purl[0]"
                :preview-src-list="img.purl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.8</p>
                <!-- 5 -->
                <p class="describe">
                  5.确认过的账单就会转至“收款及发票登记跟踪”方框（如图2.9）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.qurl[0]"
                :preview-src-list="img.qurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.9</p>
                 <!-- 6 -->
                <p class="describe">
                  5.在该界面下（图2.10），可快速导入搜索栏中对应的账单，且无论账单为何状态（是否结算，是否支付）在哪个模块，都可以导出为表格并将该账单所有状态如发票号、实收款等都导出为表格（图2.10.1）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.rurl[0]"
                :preview-src-list="img.rurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.10</p>
                <el-image 
                style="width:100%;"
                :src="img.surl[0]"
                :preview-src-list="img.surl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.10.1</p>
              </div>
              <!-- 账单回退 -->
              <div class="range" id="zdht">
                <h4 class="title">账单回退</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击紫色方框“收款及发票登记跟踪”进行账单回退,勾选需回退的账单（如图2.11）点击批量回退按钮（如图2.12）
                </p>
                <el-image 
                style="width:100%"
                :src="img.turl[0]"
                :preview-src-list="img.turl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.11</p>
                <el-image 
                style="width:100%"
                :src="img.uurl[0]"
                :preview-src-list="img.uurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.12</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      lists: [
        {
          name: "管理后台入口",
          target: "#glhtrk",
        },
        {
          name: "账单管理",
          target: "#zdgl",
        },
        {
          name: "账单筛选和排序",
          target: "#zdsxhpx",
        },
        {
          name: "账单选择和确认",
          target: "#zdxzhqr",
        },
        {
            name:"账单回退",
            target:"#zdht"
        }
      ],
      link: 0,
      img: {
        aurl: [this.$img + "cwzdgl/2.1.png"],
        burl: [this.$img + "cwzdgl/2.2.png"],
        curl: [this.$img + "cwzdgl/2.3.png"],
        durl: [this.$img + "cwzdgl/2.2.1.png"],
        eurl: [this.$img + "cwzdgl/2.3.1.png"],
        furl: [this.$img + "cwzdgl/2.2.2.png"],
        gurl: [this.$img + "cwzdgl/2.3.2.png"],
        hurl: [this.$img + "cwzdgl/2.2.3.png"],
        iurl: [this.$img + "cwzdgl/2.3.3.png"],
        jurl: [this.$img + "cwzdgl/2.3.4.png"],
        kurl: [this.$img + "cwzdgl/2.3.5.png"],
        lurl: [this.$img + "cwzdgl/2.4.png"],
        murl: [this.$img + "cwzdgl/2.5.png"],
        nurl: [this.$img + "cwzdgl/2.6.png"],
        ourl: [this.$img + "cwzdgl/2.7.png"],
        purl: [this.$img + "cwzdgl/2.8.png"],
        qurl: [this.$img + "cwzdgl/2.9.png"],
        rurl: [this.$img + "cwzdgl/2.10.png"],
        surl: [this.$img + "cwzdgl/2.10.1.png"],
        turl: [this.$img + "cwzdgl/2.11.png"],
        uurl: [this.$img + "cwzdgl/2.12.png"],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getscroll);
  },
  methods: {
    tolink(index) {
      this.link = index;
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (result < 4) {
        this.tolink(0);
      } else if (result >= 4 && result < 58) {
        this.tolink(1);
      } else if (result >= 58 && result < 64) {
        this.tolink(2);
      } else if (result >= 64 && result < 97) {
        this.tolink(3);
      }  else {
        this.tolink(4);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.enterance {
  background: #5e6d82;
  color: aliceblue;
  border-radius: 8px;
  font-size: 14px;
  /* color: #5e6d82; */
}
.enterance li {
  padding: 5px 10px;
}
.describe::first-letter {
  font-size: 20px;
  font-weight: 700;
  text-shadow: 3px 2px 2px #4fc08d;
}
.info{
    font-size: 14px;
    color: red;
}
</style>